import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import NodeIcon from '../../assets/svg/nodejs-plain.svg';
import PythonIcon from '../../assets/svg/python-plain.svg';
import FeatureCardsSection from '../components/featureCardsSection';
import KafkaIcon from '../../assets/svg/kafka-plain.svg';
import PostgresIcon from '../../assets/svg/postgresql-plain.svg';
import MongoIcon from '../../assets/svg/mongodb-plain.svg';
import NestIcon from '../../assets/svg/nestjs-plain.svg';
import RedisIcon from '../../assets/svg/redis-plain.svg';
import GolangIcon from '../../assets/svg/golang.svg';
import TechBox from '../components/techBox';

import MicroservicesIcon from '../../assets/svg/microservices.svg';
import CloudIcon from '../../assets/svg/cloud.svg';
import RobotIcon from '../../assets/svg/robot.svg';
import StopwatchIcon from '../../assets/svg/stopwatch.svg';
import SEO from '../components/seo';

const BackendPage = () => {
  const data = useStaticQuery(graphql`
        query BackendImages {
            aplikacjeSerwerowe: file(
                relativePath: {
                    eq: "aplikacje-serwerowe-l.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowaSRV: file(
                relativePath: {
                    eq: "kompleksowa-usluga.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <SubLayout
      title={(
        <>
          Aplikacje
          {' '}
          <span>Serwerowe</span>
        </>
)}
      subtitle="Wydajne i skalowalne aplikacje działające po stronie serwera"
      img="/img/biuro-komputery-programowanie.jpg"
    >
      <SEO title="Aplikacje serwerowe - backend w iqcode Software House" description="Tworzymy zaawansowane aplikacje działające po stronie serwera w architekturze microservices z wykorzystaniem Pythona oraz Node.js" />
      <div style={{ boxShadow: '3px 3px 30px #00000070' }}>
        <FeatureSectionLight
          title={(
            <>
              Aplikacje
              {' '}
              <span>Backendowe</span>
            </>
                      )}
          img={<Img alt="aplikacje serwerowe backendowe" fluid={data.aplikacjeSerwerowe.childImageSharp.fluid} />}
          desc={(
            <>
              <p>
                Niemal każdy aplikacja progresywna, mobilna czy przeglądarkowa wymaga odpowiednio przygotowanego backendu - programu działającego po stronie serwera odpowiedzialnego za operacje na danych, uwierzytelnianie użytkowników czy analitykę i administrację.
              </p>
              <p>
                Tworzymy wydajne i skalowalne aplikacje serwerowe uruchamiane w środowiskach zapewniających wysoką dostępność i bezpieczeństwo danych. Realizujemy projekty począwszy od nieskomplikowanych MVP startupów technologicznych a zakończywszy na złożonych systemach rozproszonych w architekturze mikrousług, rozwiązaniach uczenia maszynowego czy systemach opartych o przetwarzanie danych w czasie rzeczywistym.
              </p>
            </>
                      )}
          bottom={(
            <TechBox
              fullWidth
              technologies={[
                {
                  icon: <MicroservicesIcon />,
                  name: 'Architektura Mikrousług',
                },
                {
                  icon: <RobotIcon />,
                  name: 'Uczenie Maszynowe',
                },
                {
                  icon: <CloudIcon />,
                  name: (<>
                    Aplikacje
                    <br />
                    {' '}
                    Cloud-Native
                         </>),
                },
                {
                  icon: <StopwatchIcon />,
                  name: 'Szybkie Prototypowanie',
                },
              ]}
            />
)}
        />

      </div>
      <div className="section" id="aplikacje-mobilne">
        <div className="promo overlay-holder z-depth-3">
          <div className="diagonal-overlay-light" />
          <div className="rel">
            <h3>
              Chcesz wycenić
              {' '}
              <span>Projekt</span>
              ?
            </h3>
            <Link className="btn" to="/kontakt/">Porozmawiajmy</Link>
          </div>
        </div>
        <FeatureCardsSection
          id="backend"
          title={(
            <>
              Technologie
              {' '}
              <span>Backendowe</span>
            </>
                  )}
          subtitle="Napędzające aplikacje działające po stronie serwera"
          cards={[
            {
              title: 'Node.js',
              desc:
                            'Środowisko uruchomieniowe języka JavaScript umożliwiające tworzenie wydajnych aplikacji serwerowych.',
              icon: <NodeIcon />,
            },
            {
              title: 'NestJS',
              desc:
                            'Progresywny framework Node.js służący do tworzenia aplikacji serwerowych.',
              icon: <NestIcon />,
            },
            {
              title: 'Python',
              desc:
                            'Najpopularniejszy na świecie język programowania, który wykorzystujemy do budowy aplikacji serwerowych, analizy danych i uczenia maszynowego.',
              icon: <PythonIcon />,
            },
            {
              title: 'Golang',
              desc:
                            'Nowoczesny język programowania, w którym tworzymy wydajne aplikacje serwerowe oraz rozszerzenia do technologii DevOps.',
              icon: <GolangIcon />,
            },
            {
              title: 'Redis',
              desc:
                            'Baza danych działająca w pamięci operacyjnej najczęściej wykorzystywana jako pamięć podręczna aplikacji serwerowych.',
              icon: <RedisIcon />,
            },
            {
              title: 'PostgreSQL',
              desc:
                            'Otwartoźródłowa relacyjna baza danych zapewniająca skalowalność i wysoką dostępność przez mechanizm replikacji.',
              icon: <PostgresIcon />,
            },
            {
              title: 'MongoDB',
              desc:
                            'Nowoczesna baza danych nurtu NoSQL umożliwiająca przechowywanie dużych rozmiarów danych w modelu dokumentowym.',
              icon: <MongoIcon />,
            },
            {
              title: 'Apache Kafka',
              desc:
                            'Rozproszona i skalowalna platforma przetwarzania strumieniowego wykorzystywana do komunikacji w architekturze mikrousług.',
              icon: <KafkaIcon />,
            },
          ]}
        />
        <div className="promo overlay-holder z-depth-3">
          <div className="diagonal-overlay-light" />
          <div className="rel">
            <h3>
              Potrzebujesz doradztwa
              {' '}
              <span>projektowego</span>
              ?
            </h3>
            <Link className="btn" to="/consulting-it/">Consulting IT</Link>
          </div>
        </div>
        <FeatureSectionLight
          title={(
            <>
              <span>Kompleksowa </span>
              Realizacja Projektów IT
            </>
                  )}
          mb0
          img={(
            <div style={{ padding: '10%' }}>
              <Img
                alt="kompleksowe usługi programistyczne"
                fluid={data.kompleksowaSRV.childImageSharp.fluid}
                imgStyle={{ width: 'auto' }}
              />
            </div>
                  )}
          desc={(
            <>
              <p>
                Aplikacje serwerowe to nie jedyny obszar naszej specjalizacji. Oferujemy kompleksową realizację projektów informatycznych począwszy od projektowania oprogramowania i inżynierii wymagań, poprzez prace programistyczne i doradztwo projektowe związane z debiutem rynkowym aplikacji, a zakończywszy na wsparciu technicznym, analityce i ciągłym rozwoju produktu cyfrowego.
              </p>
              <p>
                Ponieważ dysponujemy szerokim wachlarzem technologii backendowych, mobilnych i webowych, możemy realizować złożone projekty programistyczne łączące wiele dziedzin IT.
              </p>
            </>
                  )}
          link="Porozmawiajmy"
          to="/kontakt"
        />

        <AllServices />
      </div>
    </SubLayout>
  );
};

export default BackendPage;
